import React from 'react';
import { Image } from "react-bootstrap";
import { constants } from '../redux/constants';

interface SimCardCountryImageProps {
    country: string;
    simStyle: any;
    simWidth: string;
    countryImgWidth: string;
    simHeight?: string;
}

const SimCardCountryImage: React.FC<SimCardCountryImageProps> = (props: SimCardCountryImageProps) => {
    const simCardImage = props.country === constants.CANADA ? '/images/phonebox-sim.png' : '/images/phonebox-sim-us.png';
    const countryImage = props.country === constants.CANADA ? "/images/canada.png" : "/images/us.png";
    return (
        <div style={{ position: 'relative' }}>
            <Image
                style={props.simStyle}
                width={props.simWidth}
                height={props.simHeight}
                src={simCardImage}
                alt="phonebox sim card"
            />
            <div style={{ position: "absolute", top: -20, right: 0 }}>
                <Image fluid width={props.countryImgWidth} src={countryImage} />
            </div>
        </div>
    );
}

export default SimCardCountryImage;