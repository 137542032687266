import React from "react";
import { Trans } from "react-i18next";
import styled from "styled-components";

interface PriceDisplayProps {
  price: number;
  currency: string;
  timeUnit: string;
  blackFriday?: boolean;
}

const PriceDisplay: React.FC<PriceDisplayProps> = (props: PriceDisplayProps) => {
  return (
    <PriceDisplayContainer blackFriday={props.blackFriday}>
      <PriceDisplayWrapper>
        <PriceDisplayCurrencySymbol blackFriday={props.blackFriday}>$</PriceDisplayCurrencySymbol>
        <PriceDisplayMoney blackFriday={props.blackFriday}>{props.price}</PriceDisplayMoney>
      </PriceDisplayWrapper>
      <PriceDisplayCurrencyWrapper>
        <PriceDisplayCurrency blackFriday={props.blackFriday}>
          <Trans>{props.currency}</Trans>
        </PriceDisplayCurrency>
        <PriceDisplayTimeUnit blackFriday={props.blackFriday}>
          /<Trans>{props.timeUnit}</Trans>
        </PriceDisplayTimeUnit>
      </PriceDisplayCurrencyWrapper>
    </PriceDisplayContainer>
  );
};

interface BlackFridayProps {
  blackFriday: boolean
}

const PriceDisplayContainer = styled.div<BlackFridayProps>`
  display: flex;
  align-items: center;
  flex: 2;
  gap: 10px;
  justify-content: center;
  background-color: ${props => props.blackFriday ? '#292929' : '#fff'};
`;

const PriceDisplayWrapper = styled.div`
  display: flex;
  align-items: start;
`;

const PriceDisplayCurrencySymbol = styled.div<{ blackFriday: boolean }>`
  text-align: center;
  font: normal normal bold 20px/55px Poppins;
  letter-spacing: 0px;
  color: ${props => props.blackFriday ? '#fff' : '#333333'} ;
  margin-bottom: 5px;

  @media (max-width: 1366px) {
    font: normal normal bold 20px/45px Poppins;
  }

  @media (max-width: 768px) {
    font: normal normal bold 12px/45px Poppins;
  }
`;

const PriceDisplayMoney = styled.div<{ blackFriday: boolean }>`
  text-align: center;
  font: normal normal bold 55px Poppins;
  letter-spacing: 0px;
  color: ${props => props.blackFriday ? '#fff' : '#333333'};

  @media (max-width: 1366px) {
    font: normal normal bold 45px Poppins;
  }

  @media (max-width: 768px) {
    font: normal normal bold 30px Poppins;
  }
`;

const PriceDisplayCurrencyWrapper = styled.div``;

const PriceDisplayCurrency = styled.div<{ blackFriday: boolean }>`
  width: 33px;
  height: 16px;
  background: ${props => props.blackFriday ? '#fff' : '#333'} 0% 0% no-repeat padding-box;
  border-radius: 20px;
  text-align: center;
  font: normal normal bold 8px/15px Poppins;
  letter-spacing: 0px;
  color: ${props => props.blackFriday ? '#FF4141' : '#fff'} ;
`;

const PriceDisplayTimeUnit = styled.div<{ blackFriday: boolean }>`
  text-align: center;
  font: normal normal bold 14px Poppins;
  letter-spacing: 0px;
  color: ${props => props.blackFriday ? '#fff' : '#333'};
  white-space: nowrap;
`;

export default PriceDisplay;
