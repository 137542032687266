import React, { useState, useEffect } from "react";
import PageHeader from "../components/PageHeader";
import styled from "styled-components";
import PageLayout from "../components/PageLayout";
import { Container } from "react-bootstrap";
import { Image } from "react-bootstrap";
import { Row } from "react-bootstrap";
import { Col } from "react-bootstrap";
import Button from "../components/Button";
import { useSelector } from "react-redux";
import { RootState } from "../redux/reducers/rootReducer";
import { graphql } from "gatsby";
import { Trans, useTranslation } from "react-i18next";
import { formatPhoneData, goTo } from "../utilities/utils";
import { planIncludesDescription } from "../utilities/constants";
import PriceDisplay from "../components/PriceDisplay";
import { constants } from "../redux/constants";
import SimCardCountryImage from "../components/SimCardCountryImage";
import { useI18next } from "gatsby-plugin-react-i18next";
import AppSupportArticle from "../components/AppSupportArticle";

interface PlanInfoProps {
  id: number;
  price: number;
  data: string;
  carrier: string;
  isPromotion: boolean;
  currency: string;
  planTypeD: string;
  path: string;
  planType: string;
  country: string;
  enableESIM?: boolean;
  enableShipping?: boolean;
}

const Plan = () => {
  let shopDetails = useSelector((state: RootState) => state.shop);
  const { t } = useTranslation();
  const boxImage = "/images/shop/box.png";
  const isUSA = shopDetails.country === constants.USA;

  const [show, setShow] = useState(true);
  const [mobile, setMobile] = useState(false);

  const { language } = useI18next();

  let internationalCallingArticle = {
    title: "(List of countries)",
    articleId: 217,
  };
  let internationalCallingArticleUSA = {
    title: "(List of countries)",
    articleId: 110,
  };

  const getTimeUnit = () => {
    const days = shopDetails.planTypeD.toString();
    if (days === "90") {
      return "90 days";
    } else if (days === "7") {
      return "week";
    } else if (days === "14") {
      return "2 weeks";
    } else if (days === "15") {
      return "15 days";
    } else {
      return "month";
    }
  };

  const isTelusPlan = shopDetails.planCarrier.toLowerCase() === "telus";

  useEffect(() => {
    if (!shopDetails.planId || shopDetails.planId === 0) {
      goTo("https://gophonebox.com/plans");
    }
    if (shopDetails.planId) {
      const url = new URL(window.location.href);
      const params = new URLSearchParams(url.search);
      params.set("planid", shopDetails.planId.toString());
      window.history.replaceState(
        null,
        document.title,
        window.origin + "/plan-info/?" + params.toString()
      );
    }
  }, []);

  // gtag useEffect
  useEffect(() => {
    // view item
    // ask trena digital about this if it's incorrect.
    // @ts-ignore
    window.dataLayer = window.dataLayer || [];
    // @ts-ignore
    window.dataLayer.push({
      event: "view_item",
      value: shopDetails.planId,
      ecommerce: {
        items: [
          {
            item_name: shopDetails.data,
            item_id: shopDetails.planId,
            price: shopDetails.plan_fee,
            item_category: isUSA
              ? "USA Prepaid"
              : shopDetails.isPostpaid
              ? "Canada Postpaid"
              : "Canada Prepaid",
            item_variant: shopDetails.planTypeD,
            item_list_name: isUSA
              ? "USA Prepaid"
              : shopDetails.isPostpaid
              ? "Canada Postpaid"
              : "Canada Prepaid",
            index: 0,
          },
        ],
      },
    });
  }, []);

  useEffect(() => {
    const handleWindowResize = () => {
      if (window.innerWidth < 769) {
        setMobile(true);
      } else setMobile(false);
    };
    handleWindowResize();
    window.addEventListener("resize", handleWindowResize);

    return () => window.removeEventListener("resize", handleWindowResize);
  }, [mobile]);

  const onClickBack = () => {
    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search);
    // if params includes plan ID, we want them to press the back button without being instantly directed to plan-info
    // we do this by clearing the planid param.
    if (params.get("planid")) {
      params.set("planid", "");
      window.location.replace(window.origin + "/plans/?" + params.toString());
      return;
    }
    goTo("/plans");
  };

  const onClickPurchase = () => {
    // add to cart
    // ask trena digital about this if it's incorrect.
    // @ts-ignore
    window.dataLayer = window.dataLayer || [];
    // @ts-ignore
    window.dataLayer.push({
      event: "add_to_cart",
      value: shopDetails.planId,
      ecommerce: {
        items: [
          {
            item_name: shopDetails.data,
            item_id: shopDetails.planId,
            price: shopDetails.plan_fee,
            item_category: isUSA
              ? "USA Prepaid"
              : shopDetails.isPostpaid
              ? "Canada Postpaid"
              : "Canada Prepaid",
            item_variant: shopDetails.planTypeD,
            item_list_name: isUSA
              ? "USA Prepaid"
              : shopDetails.isPostpaid
              ? "Canada Postpaid"
              : "Canada Prepaid",
            index: 0,
          },
        ],
      },
    });

    goTo("/info-fill-up");
  };

  return (
    <PageLayout>
      <title>Select Your Plan | PhoneBox</title>
      {mobile ? null : (
        <PageHeader
          text="Shop"
          imageSrc="/images/activation/activation-header-icon.png"
        />
      )}
      <Container style={{ padding: "40px 0px" }}>
        <BackArrow
          onClick={onClickBack}
          style={{
            color: "#000",
            fontWeight: 500,
            fontSize: 20,
            marginLeft: "2rem",
          }}
        >
          {"<"} <Trans>Back</Trans>
        </BackArrow>

        {mobile ? (
          <>
            <ResCont>
              <ResType>
                <ResPlanType>
                  <Trans>
                    {shopDetails.isPostpaid
                      ? "Monthly Plan"
                      : shopDetails.dataOnly
                      ? "Data Only Plan"
                      : "Prepaid Plan"}
                  </Trans>
                </ResPlanType>
              </ResType>
              <ResAction>
                <Right>
                  <SimCardCountryImage
                    country={shopDetails.country}
                    simStyle={{ borderRadius: "20px", marginBottom: "60px" }}
                    simWidth="100%"
                    simHeight="140px"
                    countryImgWidth="50px"
                  />
                  <Button
                    color="red"
                    text="Purchase"
                    padding="10px 35px"
                    onClick={onClickPurchase}
                    itemObject={undefined}
                  ></Button>
                </Right>
                <Left>
                  {shopDetails.plan_fee === 55 && isUSA ? (
                    <ResDataUnlimited>Unlimited</ResDataUnlimited>
                  ) : (
                    <ResData>{shopDetails.data}</ResData>
                  )}
                  <Price>
                    <PriceDisplay
                      price={shopDetails.plan_fee}
                      currency={shopDetails.currency}
                      timeUnit={getTimeUnit()}
                    ></PriceDisplay>
                  </Price>
                  {!show ? (
                    <ResDetails className="my-3" onClick={() => setShow(true)}>
                      <Trans>See Details</Trans>
                    </ResDetails>
                  ) : (
                    <ResDetails className="my-3" onClick={() => setShow(false)}>
                      <Trans>See less</Trans>
                    </ResDetails>
                  )}
                  <ResImg>
                    <Image
                      width="40px"
                      src={boxImage}
                      alt="shipping box"
                      fluid
                    />
                    <ResText>
                      <Trans>Free shipping worldwide</Trans>
                    </ResText>
                  </ResImg>
                </Left>
              </ResAction>
            </ResCont>
          </>
        ) : (
          <Row>
            <Col sm={6} md={6} className="p-5">
              <SimCardCountryImage
                country={shopDetails.country}
                simStyle={{ borderRadius: "20px" }}
                simWidth="100%"
                countryImgWidth="70px"
              />
            </Col>
            <Col sm={6} md={6} className="p-5">
              <PlanType>
                <Trans>
                  {shopDetails.isPostpaid
                    ? "Monthly Plan"
                    : shopDetails.dataOnly
                    ? "Data Only Plan"
                    : "Prepaid Plan"}
                </Trans>
              </PlanType>
              <Data>
                {shopDetails.plan_fee === 55 && isUSA ? (
                  <Trans>Unlimited</Trans>
                ) : (
                  shopDetails.data
                )}
              </Data>
              <Price>
                <PriceDisplay
                  price={shopDetails.plan_fee}
                  currency={shopDetails.currency}
                  timeUnit={getTimeUnit()}
                ></PriceDisplay>
              </Price>
              <div className="d-inline-block text-center my-4">
                <Button
                  color="red"
                  text="Purchase"
                  onClick={onClickPurchase}
                  itemObject={undefined}
                ></Button>
                {!show ? (
                  <Text
                    style={{ textDecoration: "underline", cursor: "pointer" }}
                    className="my-3"
                    onClick={() => setShow(true)}
                  >
                    <Trans>See Details</Trans>
                  </Text>
                ) : (
                  <Text
                    style={{ textDecoration: "underline", cursor: "pointer" }}
                    className="my-3"
                    onClick={() => setShow(false)}
                  >
                    <Trans>See less</Trans>
                  </Text>
                )}
              </div>
              <div className="d-flex flex-row justify-content-start align-items-center">
                <Image width="50px" src={boxImage} alt="shipping box" fluid />
                <Text className="my-0 mx-3">
                  <Trans>Free shipping worldwide</Trans>
                </Text>
              </div>
            </Col>
          </Row>
        )}

        {show && !shopDetails.dataOnly && (
          <>
            <Row>
              <Col sm={12} md={12} className="px-5 py-3">
                <Title>
                  <Trans>Details</Trans>
                </Title>
                <Cont className="my-4">
                  <div className="d-flex">
                    {language === "sp" ? (
                      <Unlimited>
                        <Trans>UNLIMITED</Trans>
                        <br />
                        <Trans>UNLIMITEDf</Trans>
                      </Unlimited>
                    ) : (
                      <Unlimited>
                        <Trans>UNLIMITED</Trans>
                      </Unlimited>
                    )}
                  </div>
                  <ResSeeMore>
                    <Trans>{shopDetails.country}-wide Calling,</Trans> <br />{" "}
                    {/* add space for i18n translation quirk */}
                    <Trans>
                      Texting & {isUSA ? " " : ""}International Messaging
                    </Trans>
                  </ResSeeMore>
                </Cont>
              </Col>
            </Row>
            <Row>
              <Col
                sm={12}
                md={6}
                className="px-5 d-flex flex-column"
                style={{
                  paddingTop: isUSA ? 48 : 0,
                  justifyContent: isUSA ? "normal" : "end",
                }}
              >
                <Detail>
                  {isUSA ? (
                    <>
                      {planIncludesDescription[shopDetails.planId] &&
                        planIncludesDescription[shopDetails.planId].map(
                          (entry, i) => {
                            if (entry.includes("75+ Countries")) {
                              let index = entry.indexOf("75+ Countries");
                              let firstPart = entry.substring(0, index);

                              return (
                                <DetailItem key={i}>
                                  <Trans>{firstPart}</Trans>
                                  <span
                                    style={{ color: "#22C3F1" }}
                                    data-elevio-article="82"
                                    className="_elevio_underline ctxUnderline"
                                    data-elevio-unique-id="8"
                                  >
                                    75+ <Trans>Countries</Trans>
                                  </span>
                                </DetailItem>
                              );
                            } else if (entry.includes("300 minutes")) {
                              return (
                                <DetailItem>
                                  <Trans>{entry}</Trans>{" "}
                                  <InternationalCallingLink
                                    data-elevio-article={
                                      internationalCallingArticleUSA.articleId
                                    }
                                  >
                                    <Trans>
                                      {internationalCallingArticleUSA.title}
                                    </Trans>
                                  </InternationalCallingLink>
                                </DetailItem>
                              );
                            }

                            return (
                              <DetailItem key={i}>
                                <Trans>{entry}</Trans>
                              </DetailItem>
                            );
                          }
                        )}
                    </>
                  ) : (
                    <div>
                      {shopDetails.data + "" !== "Unlimited" && (
                        <DetailItem>
                          {formatPhoneData(
                            shopDetails.data,
                            shopDetails.planTypeD.toString()
                          ) +
                            " " +
                            t(
                              `high speed ${
                                isTelusPlan ? "4G LTE/5G" : "5G"
                              } data`
                            )}
                        </DetailItem>
                      )}
                      <DetailItem>
                        <Trans>
                          UNLIMITED {shopDetails.country} wide calling
                        </Trans>
                      </DetailItem>
                      <DetailItem>
                        <Trans>
                          UNLIMITED international text and picture messaging
                        </Trans>
                      </DetailItem>
                      {isTelusPlan && (
                        <div>
                          <div
                            style={{
                              display: "inline-block",
                              paddingRight: 10,
                            }}
                          >
                            <DetailItem>
                              <Trans>UNLIMITED international calling</Trans>
                            </DetailItem>
                          </div>
                          <InternationalCallingLink
                            data-elevio-article={
                              internationalCallingArticle.articleId
                            }
                          >
                            <Trans>{internationalCallingArticle.title}</Trans>
                          </InternationalCallingLink>
                        </div>
                      )}
                      {!isTelusPlan && (
                        <DetailItem>
                          <Trans>
                            Supported on the {shopDetails.planCarrier} Network
                          </Trans>
                        </DetailItem>
                      )}
                      <DetailItem>
                        <Trans>Call Display</Trans>
                      </DetailItem>
                      <DetailItem>
                        <Trans>Voicemail - Up to 35 minutes of messages</Trans>
                      </DetailItem>
                      {!shopDetails.noTopUpFee &&
                        shopDetails.data + "" !== "Unlimited" && (
                          <DetailItem>
                            <Trans>
                              Data{" "}
                              {shopDetails.isPostpaid ? "Overage" : "Top Up"}{" "}
                              Rate: $15 / 1GB
                            </Trans>
                          </DetailItem>
                        )}
                    </div>
                  )}
                </Detail>
              </Col>
              <Col sm={12} md={6} className="px-5">
                <Title>
                  <Trans>Please Note:</Trans>
                </Title>
                <Detail>
                  <DetailItem>
                    <Trans>Plan does not include International Roaming</Trans>
                  </DetailItem>
                  {shopDetails.country === "Canada" && (
                    <DetailItem>
                      <Trans>
                        Provincial 9-1-1 fees are extra (If applicable)
                      </Trans>
                    </DetailItem>
                  )}
                  {shopDetails.simcard_no === "" && (
                    <DetailItem>
                      <Trans>
                        A SIM card fee of $10 will be charged
                        {shopDetails.fromPartner && " (If applicable)"}
                      </Trans>
                    </DetailItem>
                  )}
                  {shopDetails.planTypeD.toString() === "30" && isUSA && (
                    <DetailItem>
                      <span>
                        <Trans>International Call Add-on: </Trans>{" "}
                        <InternationalCallingLink
                          data-elevio-article={
                            internationalCallingArticleUSA.articleId
                          }
                        >
                          <Trans>{internationalCallingArticleUSA.title}</Trans>
                        </InternationalCallingLink>
                      </span>
                      <Detail>
                        <DetailItem>
                          <Trans>500 international minutes for $5</Trans>
                        </DetailItem>
                        <DetailItem>
                          <Trans>Unlimited minutes for $10</Trans>
                        </DetailItem>
                      </Detail>
                    </DetailItem>
                  )}
                  {isUSA &&
                    (shopDetails.planTypeD.toString() === "7" ||
                      shopDetails.planTypeD.toString() === "15") && (
                      <>
                        <DetailItem>
                          <Trans>
                            International calling option is unavailable for this
                            plan.
                          </Trans>
                        </DetailItem>
                        <DetailItem>
                          <Trans>
                            The plan is strictly limited to{" "}
                            {shopDetails.planTypeD.toString()} days.
                          </Trans>
                        </DetailItem>
                        <DetailItem>
                          <Trans>Term extension is unavailable.</Trans>
                        </DetailItem>
                        <DetailItem>
                          <Trans>SIM card is not allowed to be reused.</Trans>
                        </DetailItem>
                      </>
                    )}
                  {isUSA && shopDetails.planTypeD.toString() !== "7" && (
                    <DetailItem>
                      <Trans>Data Top-up rates: $10/GB</Trans>
                    </DetailItem>
                  )}
                </Detail>
              </Col>
            </Row>
          </>
        )}

        {show && shopDetails.dataOnly && (
          <>
            <Row>
              <Col sm={12} md={6} className="px-5 d-flex flex-column">
                <Title>
                  <Trans>Details</Trans>
                </Title>
                <Detail>
                  <div>
                    <DetailItem>Supported on the Rogers Network</DetailItem>
                  </div>
                </Detail>
              </Col>
              <Col sm={12} md={6} className="px-5">
                <Title>
                  <Trans>Please Note:</Trans>
                </Title>
                <Detail>
                  <DetailItem>
                    <Trans>Plan does not include International Roaming</Trans>
                  </DetailItem>
                  {shopDetails.country === "Canada" && (
                    <DetailItem>
                      <Trans>
                        Provincial 9-1-1 fees are extra (If applicable)
                      </Trans>
                    </DetailItem>
                  )}
                  {shopDetails.dataOnly && (
                    <DetailItem>
                      <Trans>
                        Calling & Texting services are not available
                      </Trans>
                    </DetailItem>
                  )}
                  {shopDetails.simcard_no === "" && (
                    <>
                      {shopDetails.fromPartner && (
                        <DetailItem>
                          {t(
                            "A SIM card fee of $10 will be charged (if applicable)"
                          )}
                        </DetailItem>
                      )}
                      {!shopDetails.fromPartner && (
                        <DetailItem>
                          <Trans>A SIM card fee of $10 will be charged</Trans>
                        </DetailItem>
                      )}
                    </>
                  )}
                </Detail>
              </Col>
            </Row>
          </>
        )}
        {!isTelusPlan && (
          <Row>
            <Col sm={12} md={12} className="px-5 py-3">
              <DetailText>
                <Trans>*5G wherever available, depending on location</Trans>
              </DetailText>
            </Col>
          </Row>
        )}
      </Container>
    </PageLayout>
  );
};

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default Plan;

const Title = styled.p`
  font-weight: 400;
  font-size: 120%;
  font-weight: 500;
`;

const Text = styled.div`
  margin-left: 0.5rem;
  width: 100%;
  display: flex;
`;

const ResSeeMore = styled.div`
  margin-left: 0.5rem;
  width: 100%;
  display: flex;

  @media (max-width: 460px) {
    display: flex;
    margin-left: 0rem;
    margin-top: 25px;
    width: 100%;
  }
`;

const ResCont = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2rem;
`;

const ResType = styled.div`
  display: flex;
  margin-bottom: 40px;
`;

const ResAction = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
`;

const Right = styled.div`
  display: flex;
  flex-direction: column;
  width: 55%;
  align-items: center;
`;

const Left = styled.div`
  display: flex;
  flex-direction: column;
  width: 40%;
  align-items: center;
`;

const ResPlanType = styled.h1`
  font-size: 20px;
  padding: 5px 12px;
  font-weight: 600;
  background-color: #e5f4fb;
  color: #0194ca;
  display: inline-block;
`;

const ResData = styled.h1`
  font-size: 50px;
  color: #0194ca;
  font-weight: 700;
  margin-bottom: 20px;

  @media (max-width: 440px) {
    font-size: 40px;
  }
  @media (max-width: 350px) {
    font-size: 30px;
  }
`;

const ResDataUnlimited = styled.h1`
  font-size: 40px;
  color: #0194ca;
  font-weight: 700;
  margin-bottom: 20px;

  @media (max-width: 440px) {
    font-size: 30px;
  }
  @media (max-width: 350px) {
    font-size: 20px;
  }
`;

const ResAmount = styled.h5`
  font-size: 30px;
  font-weight: 700;
  margin: 0px 10px;
`;

const ResTimeUnit = styled.h5`
  position: absolute;
  bottom: 0px;
  right: -10px;
  font-size: 16px;
`;

const ResDetails = styled.div`
  display: flex;
  text-decoration: underline;
  cursor: pointer;
  margin-bottom: 40px;
`;

const ResText = styled.div`
  margin-left: 0.5rem;
  width: 100%;
  display: flex;
  font-size: 12px;
`;

const ResImg = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-left: 2.5rem;

  @media (max-width: 500px) {
    padding-left: 1.5rem;
  }
  @media (max-width: 450px) {
    padding-left: 0.5rem;
  }

  @media (max-width: 405px) {
    padding-left: 0rem;
  }
`;
const Cont = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  @media (max-width: 460px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
`;

const PlanType = styled.h1`
  font-size: 30px;
  padding: 10px 20px;
  font-weight: 600;
  background-color: #e5f4fb;
  color: #0194ca;
  display: inline-block;
`;

const Data = styled.h1`
  font-size: 60px;
  color: #0194ca;
  font-weight: 700;
  margin: "10px 0px";
`;

const Price = styled.div`
  display: flex;
  position: relative;
  width: 100px;
  margin-bottom: 10px;
`;

const Icon = styled.h5`
  position: absolute;
  top: 0px;
  left: 0px;
`;

const Amount = styled.h5`
  font-size: 40px;
  font-weight: 600;
  margin: 0px 10px;
`;

const TimeUnit = styled.h5`
  position: absolute;
  bottom: 0px;
  right: -30px;
  font-size: 16px;
`;

const FeaturePlan = styled.div`
  padding: 10px 25px;
  background-image: linear-gradient(to bottom, #0194e9, #35c1d0);
  display: inline-block;
  border-radius: 20px;
  margin-right: 40px;
  p {
    margin: 0px;
    color: #fff;
  }
`;

const Unlimited = styled.h4`
  font-weight: 600;
  font-size: 20px;
  background-color: #eaeaea;
  display: inline-block;
  padding: 10px 20px;
  margin: 0px;
  white-space: nowrap;
`;

const Detail = styled.ul``;

const DetailItem = styled.li`
  font-size: 16px;
  font-weight: 300;
`;

const DetailText = styled.div`
  font-size: 12px;
  font-weight: 300;
`;

const BackArrow = styled.div`
  :hover {
    cursor: pointer;
  }
`;

const InternationalCallingLink = styled.a`
  font-family: "Poppins", sans-serif;
  color: #0494ca;
  display: inline-block;
  :hover {
    cursor: pointer;
    color: #0494ca;
  }
`;

export const Head = () => {
  return (
    <>
      <script
        dangerouslySetInnerHTML={{
          __html: `
          function scriptExists() {
            return document.querySelectorAll("script[src='https://www.googletagmanager.com/gtm.js?id=GTM-TJKD8FQ']").length > 0;
          }

          if(!scriptExists()) {
            console.log("does not exist. injecting...");
            (function(w, d, s, l, i) { w[l] = w[l] || []; w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' }); var f = d.getElementsByTagName(s)[0], j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : ''; j.async = true; j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl; f.parentNode.insertBefore(j, f); })(window, document, 'script', 'dataLayer', 'GTM-TJKD8FQ');
          } 

          var _dcq = _dcq || [];
          var _dcs = _dcs || {};
          _dcs.account = '8316321';
        
          (function() {
            var dc = document.createElement('script');
            dc.type = 'text/javascript'; dc.async = true;
            dc.src = '//tag.getdrip.com/8316321.js';
            var s = document.getElementsByTagName('script')[0];
            s.parentNode.insertBefore(dc, s);
          })();
        `,
        }}
      />
    </>
  );
};
