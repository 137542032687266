import React from "react";
import styled from "styled-components";
import { Image } from "react-bootstrap";
import { Trans } from "react-i18next";

interface PageHeaderProps {
  text: string;
  imageSrc: string;
}

const PageHeader: React.FC<PageHeaderProps> = (props: PageHeaderProps) => {
  return (
    <Wrapper>
      <Text>
        <Trans>{props.text}</Trans>
      </Text>
      <Image
        width="180px"
        src={props.imageSrc}
        alt="Partners header Icon Image"
        fluid
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  background-image: linear-gradient(to bottom, #0194e9, #35c1d0);
  height: 180px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  @media (max-width: 768px) {
    display: none;
    flex-direction: column;
  }
`;

const Text = styled.div`
  margin-right: 40px;
  color: #fff;
  font-weight: 700;
  font-size: 33px;
  display: inline-block;

  @media (max-width: 768px) {
    font-size: 23px;
  }
`;

export default PageHeader;